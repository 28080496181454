import * as React from "react";
import {useStaticQuery, graphql} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import Layout from "../components/layout";
import Header from "../components/header";
import Hero from "../components/hero";
import HomeArtists from "../components/homeartists";
import Contactform from "../components/contactform";
import {StaticImage} from "gatsby-plugin-image";
import Mouse from "../assets/Mouse.svg";
import Arrow from "../assets/Arrow1.svg";
import Play from "../assets/Play.svg";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import ImageGallery from "react-image-gallery";
import "../components/gallery.css";
import Close from "../assets/close.svg";
import Video from "../components/video";
import {Helmet} from "react-helmet";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Galeria = () => {
    const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

    const [open, toggleOpen] = React.useState(false);
    const [current, setCurrent] = React.useState(0);

    const handleOpenGallery = (index) => {
        toggleOpen(true);
        setCurrent(index);
    };
    const handleCloseGallery = () => {
        toggleOpen(false);
    };

    let newGalery = data.allFile.edges.map((item) => {
        return {
            original: item.node.childImageSharp.gatsbyImageData.images.fallback.src,
            fullscreen: item.node.childImageSharp.gatsbyImageData.images.fallback.src,
        };
    });

    return (
        <Layout>

            <Helmet>
                <title>Zdjęcia i filmy z wesel, eventów i innych - DJ Piotr Szymański</title>
                <meta name="description" content="Zdjęcia i filmy z wesel oraz wszystkich innych eventów, w których uczestniczyłem. Zobacz jak wyjątkowo może wyglądać Twoje wydarzenie!" />
                <meta http-equiv="Content-type" content="text/html;charset=UTF-8" />
            </Helmet>

            <Hero className="">
                <StaticImage
                    src="../images/hero-zdjecia.png"
                    alt="Zdjęcia i filmy"
                    placeholder="blurred"
                    layout="fullWidth"
                    className="hero"
                />
                <Header pageName="home"/>
                <div className="hero-title subpage">
                    <h1>Zdjęcia i filmy z wesel oraz eventów</h1>
                    <h3 className="line-before">Zobacz jak pracuję</h3>
                </div>
            </Hero>
            <section className="picture">
                <div>
                    <h4>Zdjęcia</h4>
                    <p>Jeśli szukacie DJ'a na swoje wesele w Warszawie to bardzo dobrze trafiliście, sprawdźcie galerię zdjęć z różnych miejsc zlokalizowanych pod Warszawą. Na zdjęciach możecie zobaczyć jak ja widzę wesele, często rustykalne - boho. Używam profesjonalnego ciepłego oświetlenia, dzięki czemu oprócz świetnego klimatu, zdjęcia wyglądają bardziej naturalnie.</p>
                </div>
                <div className="gallery-wrapper">
                    {data.allFile.edges.map((image, index) => (
                        <GatsbyImage
                            image={image.node.childImageSharp.gatsbyImageData}
                            className="gallery-open"
                            alt="galeria"
                            key={index}
                            onClick={() => handleOpenGallery(index)}
                        />
                    ))}
                </div>
            </section>
            <Dialog
                fullScreen
                open={open}
                onClose={handleCloseGallery}
                TransitionComponent={Transition}
                style={{background: "#000"}}
                className="dialog-bg"
            >
                <button className="close-btn" onClick={handleCloseGallery}>
                    <Close style={{width: "45px", height: "45px"}}/>
                </button>
                <ImageGallery
                    startIndex={current}
                    items={newGalery}
                    showPlayButton={false}
                    lazyLoad={true}
                />
            </Dialog>
            <section className="movies">
                <div>
                    <h4>Filmy</h4>
                </div>
                <div className="movies-wrapper">
                    <Video
                        videoSrcURL="https://youtu.be/tEDFWeh-mXQ"
                        videoTitle="Ewa i Łukasz"
                        videoId="NJQQG3UCsOk"
                        className="line-before"
                    >
                    </Video>
                    <Video
                        videoSrcURL="https://www.youtube.com/embed/NJQQG3UCsOk"
                        videoTitle="Marta i Robert - Patio na Wodoktach"
                        videoId="NJQQG3UCsOk"
                        className="line-before"
                    >
                    </Video>
                    <Video
                        videoSrcURL="https://player.vimeo.com/video/662746852?h=7d3eab61d9"
                        videoTitle="Weronika i Rafa"
                        videoId="7d3eab61d9"
                        className="line-after"
                    >
                    </Video>
                    <Video
                        videoSrcURL="https://player.vimeo.com/video/652456398?h=6507369c34"
                        videoTitle="Klara i Bartek"
                        videoId="6507369c34"
                        className="line-before"
                    >
                    </Video>
                    <Video
                        videoSrcURL="https://www.youtube.com/embed/dbQXort0ik8"
                        videoTitle="Paulina i Kamil"
                        videoId="dbQXort0ik8"
                        className="line-after"
                    >
                    </Video>
                </div>
            </section>

            <Contactform/>
        </Layout>
    );
};
export default Galeria;
